import { Box } from "@mui/material";
import CuentaRegresiva from "../contador";
import wallPaper from "../../assets/background.jpg";
import "../../fonts/fonts.css"


function Home() {
  const fechaObjetivo = new Date('2025-02-28T11:59:00');

  return (
    <Box
      height="100vh"
      width="100%"
      fontFamily='BODDAT'
      sx={{
        backgroundImage: `url(${wallPaper})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CuentaRegresiva fechaObjetivo={fechaObjetivo} />
    </Box>
  );
}

export default Home;
